import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql, Link } from "gatsby"
import useScrollToTop from "~/hooks/useScrollToTop"
import { I18nContext, I18nLink } from "~/components/I18n"
import { IGatsbyImageData } from "gatsby-plugin-image"

export const query = graphql`query ($id: String!) {
  site {
    siteMetadata {
      title
      author
      siteUrl
    }
  }
  wpPost(id: {eq: $id}) {
    date
    title
    content
    excerpt
    categories {
      nodes {
        id
        uri
        name
      }
    }
    featuredImage {
      node {
        localFile {
          publicURL
          name
          prettySize
          childImageSharp {
            gatsbyImageData(
              width: 800
              transformOptions: {cropFocus: ENTROPY}
              layout: CONSTRAINED
            )
          }
        }
        mediaDetails {
          height
          width
        }
      }
    }
  }
}
`

export default ({
  data: {
    wpPost: {
      date, title, excerpt, content, categories: {nodes: [category]}, featuredImage
    },
    site: {
      siteMetadata: {
        title: siteTitle,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
  wpPost: {
    date: string
    title: string
    content: string
    excerpt: string
    categories: {
      nodes: {
        id: string
        uri: string
        name: string
      }[]
    }
    featuredImage: {
      node: {
        localFile: {
          publicURL: string
          name: string
          prettySize: string
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
        mediaDetails: {
          height: number
          width: number
        }
      }
    }
  }
}>) => {
  useScrollToTop()
  const { locale } = useContext(I18nContext)

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang: locale,
          dir: "ltr",
          prefix: "og: http://ogp.me/ns#",
        }}
      >
        <title>{title}</title>
        <link rel="author" href="/humans.txt" />

        <meta name="description" content={excerpt} />
        <meta name="image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />

        <meta property="og:title" content={title} />
        <meta property="og:locale" content={locale} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={author} />
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={excerpt} />
        <meta name="twitter:image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />


      </Helmet>

      <header>
        <p className="is-size-1 is-uppercase is-font-family-serif">News</p>

        <nav className="is-font-style-italic is-uppercase has-half-margin-top">
          <I18nLink to={`/news/`}><b>{category.name}</b></I18nLink> {`//`} {title}
        </nav>
      </header>

      <article className="is-post has-double-margin-top has-double-margin-bottom prose">
        <header className="has-margin-bottom has-padding-bottom">
          <h1>{title}</h1>
        </header>

        <div className="has-padding-bottom" dangerouslySetInnerHTML={{ __html: content }} />

      </article>
    </>
  )
}
